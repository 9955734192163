body::-webkit-scrollbar {
    width: 0.25rem;
  }
  
  body::-webkit-scrollbar-track {
    background: #f1f4f4;
  }
  
  body::-webkit-scrollbar-thumb {
    background: #0f4c75;
  }