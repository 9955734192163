/* body{
        height: 85vh;


} */

@media (max-width: 1024px) {
    body {
      height: 75vh;
    }
}

@media (max-width: 768px) {
    body {
      height: 80vh;
    }
}

@media (max-width: 460px) {
    body {
      height: 85vh;
    }
}

@media (max-width: 400px) {
    body {
      height: 90vh;
    }
}
